import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';
import React from 'react';
import { Box, Divider, Flex, Grid, Heading, Text } from 'theme-ui';

import {
  Gander,
  NearbyGrouped,
  SEO,
  StayTheNight,
  TwoColumn,
} from 'components';

function OurHistory() {
  const imagesQuery = useStaticQuery(graphql`
    query HistoryImages {
      lounge: file(relativePath: { eq: "lounge@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      historyOne: file(relativePath: { eq: "history-1@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      historyTwo: file(relativePath: { eq: "history-2@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      historyThree: file(relativePath: { eq: "history-3@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { lounge, historyOne, historyTwo, historyThree } = imagesQuery;

  return (
    <>
      <SEO title="Our History" />
      <Box bg="white.100">
        <TwoColumn image={lounge.childImageSharp.fluid} bg="green.dark">
          <Heading variant="heading.xxl" mb="l" sx={{ color: 'white.100' }}>
            A traditional inn set in the stunning Yorkshire Dales
          </Heading>
          <Text
            variant="subhead"
            sx={{ color: 'black.40', fontSize: 'body', textDecoration: 'none' }}
            href="#history"
            as="a"
          >
            Learn more
          </Text>
        </TwoColumn>

        <Box
          pt={['xl', 'xxl', 'xxxl']}
          bg="black.5"
          px={['m', null, 0]}
          id="history"
        >
          <Box variant="boxes.measure" mb={['xl', 'xxl', 'xxxxl']}>
            <Heading variant="heading.xl">Our History</Heading>
            <Divider />
            <Text as="p">
              The Station Inn, licensed in 1879, was built around the same time
              as the viaduct and doubled as both farm and hostelry both a pub
              and a farm. John Kilburn, who was the local school master for over
              30 years, was its first landlord. With John already in his late
              70s much of the work fell to his wife Sarah and their son Tom, who
              later took over from his father. By the 1960’s much of the
              accompanying farm land had been sold off with the last landlords
              to still operate both Inn and (by then) a small holding being
              Dorothy and John Menheneott.
            </Text>
          </Box>

          <Box variant="boxes.figure" mb={['xl', 'xxl', 'xxxl']}>
            <Image
              fluid={historyOne.childImageSharp.fluid}
              style={{ display: 'block' }}
            />
          </Box>

          <Box variant="boxes.measure" mb={['xl', 'xxl', 'xxxxl']}>
            <Text as="p">
              In the late 1970’s landlord John Giles refurbished much of the
              downstairs public areas including both opening up the area where
              the bar now stands which used to be a hay barn and replacing the
              river fed water system with the pubs own bore hole through which
              fresh dales water is extracted from deep beneath the limestone the
              Inn stands on. 2018 saw the first major refurbishment for almost
              forty years with the former stone bar moved from the middle of the
              building to its current resting position creating a much more open
              feel to the Inn and the impressive traditional Victorian range
              being installed in the snug to create another comfortable and warm
              space for visitors to rest after a day out in the Dales.
            </Text>
          </Box>

          <Box mb={['xl', 'xxl', 'xxxl']}>
            <Image
              fluid={historyTwo.childImageSharp.fluid}
              style={{ display: 'block' }}
            />
          </Box>

          <Box variant="boxes.measure" mb={['xl', 'xxl', 'xxxxxl']}>
            <Text as="p">
              The Station Inn benefits from being located next to the famous
              Ribblehead Viaduct that crosses the wide expanse of ground, known
              as Batty Moss, in the run up to the foot of Whernside. Looking
              across Batty Moss from the back window of The Station Inn, it is
              hard to believe that up to 2000 workers were housed from Batty
              Moss to the mouth of the Blea Moor tunnel in settlements with
              outlandish names such as Sebastopol, Belgravia, Jericho, Jerusalem
              and Tunnel Huts. Built in 1872, the Viaduct comprises of 24
              arches, each standing 155 feet high, and is the most impressive of
              all the many viaducts supporting the 72 mile long Settle to
              Carlisle Railway. This railway represented Victorian endeavour on
              a grand scale and was without doubt a major feat of engineering.
              It was described by The Lancaster Guardian on May 6 1876 as
              follows:
            </Text>
          </Box>

          <Box variant="boxes.figure" pb={['xl', 'xxl', 'xxxl']}>
            <Text as="blockquote" variant="quote">
              The country through which the line passes is amongst the wildest
              and most romantic in England. It is a continued succession of high
              hills with intervening valleys, so that the line is alternately
              carried over viaducts, or through cuttings, or under hills
              hundreds of feet in height.
            </Text>
          </Box>
        </Box>

        <Box
          pt={['xl', 'xxl', 'xxxxxl']}
          mb={['xl', 'xxl', 'xxxxxl']}
          px={['m', null, 0]}
        >
          <Box variant="boxes.figure" mb={['xl', 'xxl', 'xxxl']}>
            <Image
              fluid={historyThree.childImageSharp.fluid}
              style={{ display: 'block' }}
            />
          </Box>

          <Box variant="boxes.measure">
            <Heading variant="heading.xl">Today</Heading>
            <Divider mb="xl" />

            <Text as="p">
              First and foremost The Station Inn is a traditional inn for the
              thriving local community. It is the warm welcome extended by both
              staff and regulars that makes The Station Inn feel like home for
              those visitors whose homes are perhaps hundreds of miles away from
              the beauty of the Yorkshire Dales.
            </Text>

            <Text as="p">
              The pub boasts a large car park which can accommodate up to 50
              vehicles. Camper vans are welcome for overnight stays. The Station
              Inn is also a popular stop for cyclists who like to take advantage
              of the miles of green lanes that cross the Yorkshire Dales, and is
              also a favourite of motorcyclists who are in the area to enjoy the
              stunning local routes. There is plenty of common land at the back
              of the pub and Ribblehead Station is directly opposite with its
              train connections to Carlisle in the north and Leeds in the south.
              There are all manner of easy ways to get to The Station Inn but,
              regardless of how you arrive, we are committed to ensuring you
              leave happy and keen to return!
            </Text>
          </Box>
        </Box>

        <Gander />

        <NearbyGrouped />

        <StayTheNight />
      </Box>
    </>
  );
}

export default OurHistory;
